.contactSection__main-container{
    width: 100%;
    /* border: 2px solid red; */
    
}

.contactSection__main-container .contact-title{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4rem;
    color: var(--headline-color);
    margin-top: 10rem;
    margin-bottom: 2rem;
}


.contactSection__box{
    width: 80%;
    /* border: 2px solid yellow; */
    /* padding: 10rem; */
    margin:3rem auto;
    padding: 1rem;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 5fr 4fr;
    grid-template-areas: 
    "form info"
    "form map";
    gap: 20px;
}

.form{
    grid-area: form;
}

.map{
    grid-area: map;
}

.info{
    grid-area: info;
}

.contact{
    padding: 40px;
    background-color: var(--card-color);
}

.contact h3{
    color: var(--cta-button-color);
    font-size: 2.5rem;
    margin-bottom: 1rem;

}

.formBox{
    position: relative;
    width: 100%;
    /* border: 2px solid red; */

}

.formBox .row50{
    display: flex;
    gap: 20px;
    
   
}

.inputBox{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 50%;
}

.formBox .row100 .inputBox{
    width: 100%;
}

.inputBox label{
    margin-bottom: 1rem;
    margin-top: 1.5rem;
    color: var(--headline-color);
    font-size: 2rem;
}

.inputBox input{
    padding: 2rem;
    outline: none;
    background-color: var(--background-color);
    border: none;

    color: var(--headline-color);
    margin-bottom: 1rem;
    font-size: 1.5rem;
    letter-spacing: 2;
}
.inputBox input:disabled{
    background-color: orangered;
    opacity: 0.5;
    /* cursor: not-allowed; */
}

.error-message{
    color: red;
    font-size: 1.3rem;
}
.inputBox textarea{
    padding: 2rem;
    outline: none;
    background-color: var(--background-color);
    border: none;
    color: var(--headline-color);
    margin-bottom: 2rem;
    font-size: 1.5rem;
    letter-spacing: 2;
    resize: none;
    min-height: 10rem;


}


.inputBox input[type="submit"]{
    background-color: var(--cta-button-color);
    color: var(--headline-color);
    border: none;
    font-size: 2rem;
    max-width: 12rem;
    cursor: pointer;
    padding: 1.5rem 1.4rem;

}



/* designing the contact info  */
.info h3{
    color: var(--background-color);
    margin-bottom: 2rem;
}

.info{
    background:var(--cta-button-color);
}

.info .infoBox div{
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}
.info .infoBox img{
    width: 3rem;
    height: 3rem;
    margin-right: 2rem;
}

.info .infoBox div p{
    font-size: 1.5rem;
    letter-spacing: 2;
}

.socialMedia{
    margin: 40px 0 0 0;
    display: flex;
    flex-gap:4px;
    padding: 0;
    align-items: first baseline;
    width: 100%;
    justify-content: flex-start;
}

.socialMedia li{
    transition: 0.2s ease-in-out;
}

.socialMedia li:hover{
    transform: scale(1.3);
}


.map{
    padding: 0 ;
}

.map iframe{
    width: 100%;
    height: 100%;
}


@media (max-width:1220px) {
    .contactSection__box{
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-template-rows: 5fr 2fr;
    padding: 1rem;
    grid-template-areas: 
    "form form"
    "info map";
    gap: 20px;
    }

    .info{
        max-height: 350px;
    }

    .map{
        max-height: 350px;
    }
}


@media (max-width:800){
    .contactSection__box{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 2fr 0.5fr 0.5fr;
        padding: 1rem;
        grid-template-areas: 
        "form form"
        "info info"
        "map map";
        gap: 20px;
        }
        .info{
            max-height: 300px;
        }
    
        .map{
            max-height: 250px;
        }

        .formBox .row50{
            display: flex;
            gap: 0;
            flex-direction: column;
        }
        .inputBox{
            width: 100%;
        }


}

@media (max-width:450px) {
    .info .infoBox div{
        display: flex;
        flex-direction: column;
    }
    .info .infoBox div p {
        /* letter-spacing: 2px; */
        font-size: 1.2rem;
        word-wrap: break-word;
    }
    .info h3 {
        color: var(--background-color);
        margin-bottom: 2rem;
        text-align: center;
        font-size: 2rem;
    }

    .socialMedia{
        margin-top: 1rem;
        flex-wrap: wrap ;

        justify-content: center;
        /* padding-left: 3rem; */
    }
    .info .infoBox img {
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
    }
    .inputBox input[type="submit"]{
        max-width: 100%;
    }
    .contact{
        padding: 8px;
    }
}