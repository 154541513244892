@import "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&family=Roboto:wght@300;400;500;700&display=swap";
html {
  box-sizing: border-box;
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  font-family: Roboto, Montserrat, sans-serif;
  font-size: 62.5%;
}

.call_to_action_btn {
  background-color: var(--cta-button-color);
  box-sizing: content-box;
  color: var(--headline-color);
  cursor: pointer;
  border: none;
  border-radius: 2rem;
  outline: none;
  padding: 1rem 2rem;
  font-size: 2rem;
}

.call_to_action_btn:hover {
  color: var(--cta-button-color);
  background-color: var(--headline-color);
}

.ghost_button {
  border: 1px solid var(--cta-button-color);
  background-color: var(--background-color);
  box-sizing: content-box;
  color: var(--headline-color);
  cursor: pointer;
  border-radius: 2rem;
  outline: none;
  margin-right: 1rem;
  padding: 1rem 2rem;
  font-size: 2rem;
}

.ghost_button:hover {
  color: var(--cta-button-color);
  border: 1px solid var(--headline-color);
}

.container {
  width: 100%;
  max-width: 85vw;
  min-height: 100vh;
  margin: 0 auto;
  padding: 1rem;
}

a {
  text-transform: capitalize;
  color: var(--headline-color);
  font-weight: bolder;
  text-decoration: none;
}

a:hover, a:focus {
  color: var(--cta-button-color);
}

li {
  list-style: none;
}

:root {
  --cta-button-color: red;
  --headline-color: #fff;
  --text-color: #e5e5e5;
  --background-color: #000;
  --design-element-color: #950101;
  --card-color: #17141d;
  --navbar-border-color: #555551;
  --card-number-color: #858282;
  --card-shadow: 1rem 0 7rem var(--background-color);
  --toggleMenuLinks-color: var(--background-color);
  --right-middleCircle-color: linear-gradient(var(--cta-button-color), var(--background-color));
  --left-upperCircle-color: linear-gradient(to left top, var(--background-color) 60%, var(--cta-button-color));
  --left-lowerCircle-color: linear-gradient(to left, var(--cta-button-color) 60%, var(--background-color));
}

.lightMode {
  --cta-button-color: #c97712;
  --headline-color: #000;
  --text-color: #000;
  --background-color: #fff8ea;
  --design-element-color: #e5ba73;
  --card-color: #faeab1;
  --navbar-border-color: #555551;
  --card-number-color: #6d6666d4;
  --card-shadow: none;
  --toggleMenuLinks-color: #000;
  --right-middleCircle-color: linear-gradient(var(--cta-button-color) 40%, var(--background-color));
  --left-upperCircle-color: linear-gradient(to left top, var(--background-color) 45%, var(--cta-button-color));
  --left-lowerCircle-color: linear-gradient(to left, var(--cta-button-color) 60%, var(--background-color));
}

.vibrate-1 {
  -webkit-animation: 2s ease-in-out infinite both vibrate-1;
  animation: 1s ease-in-out infinite both vibrate-1;
}

@keyframes vibrate-1 {
  0% {
    transform: translate(0);
  }

  20% {
    transform: translate(-2px, 2px);
  }

  40% {
    transform: translate(-2px, -2px);
  }

  60% {
    transform: translate(2px, 2px);
  }

  80% {
    transform: translate(2px, -2px);
  }

  100% {
    transform: translate(0);
  }
}

.scale-up-center {
  animation: .6s cubic-bezier(.39, .575, .565, 1) both scale-up-center;
}

@keyframes scale-up-center {
  0% {
    transform: scale(.5);
  }

  100% {
    transform: scale(1);
  }
}

.text-pop-up-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both text-pop-up-top;
}

@keyframes text-pop-up-top {
  0% {
    transform-origin: 50%;
    text-shadow: none;
    transform: translateY(0);
  }

  100% {
    transform-origin: 50%;
    text-shadow: 0 1px #ccc, 0 2px #ccc, 0 3px #ccc, 0 4px #ccc, 0 5px #ccc, 0 6px #ccc, 0 7px #ccc, 0 8px #ccc, 0 9px #ccc, 0 50px 30px #0000004d;
    transform: translateY(-50px);
  }
}

.navbar__container {
  border: 2px solid var(--navbar-border-color);
  background-color: var(--background-color);
  opacity: .8;
  z-index: 10000;
  border-radius: 2rem;
  width: 100%;
  max-height: 200px;
  margin: 0 auto;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.navbar__flex-container img {
  cursor: pointer;
  width: 100%;
  max-width: 100px;
  max-height: 100px;
  margin-left: 2rem;
}

.navbar__flex-container, .navbar__links-menu {
  flex-flow: wrap;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.navbar__flex-container :first-child {
  margin-right: auto;
}

.navbar__links-menu p {
  cursor: pointer;
  padding: 1rem 3rem;
  font-size: 2rem;
  display: block;
}

.navbarToggleMenu {
  z-index: 1000;
  margin-right: 2rem;
  display: none;
  position: relative;
}

.navbarToggleMenu svg {
  cursor: pointer;
}

.navbarToggleMenu__container {
  text-align: center;
  background: var(--design-element-color);
  box-shadow: 0px 4px 8px var(--cta-button-color);
  border-radius: 3rem;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  min-width: 21rem;
  margin-top: 1rem;
  padding: 2rem;
  display: flex;
  position: absolute;
  top: 40px;
  right: 0;
}

.navbarToggleMenu__container-links {
  margin: auto;
}

.navbarToggleMenu__container-links p a {
  color: var(--toggleMenuLinks-color);
  font-size: 2.5rem;
}

.navbarToggleMenu__container-links p {
  cursor: pointer;
  padding: 1rem;
}

.navbarToggleMenu__container-links p button.call_to_action_btn {
  background-color: var(--background-color);
  color: var(--design-element-color);
}

.sunLightMode img {
  width: 4rem;
  height: 4rem;
  margin: 0;
  padding: 0;
}

.sunLightMode {
  background: none;
  border: none;
  outline: none;
  margin-right: 4rem;
}

@media screen and (width <= 800px) {
  .navbar__links-menu {
    display: none;
  }

  .navbarToggleMenu {
    margin-right: 2rem;
    display: flex;
  }
}

.heroSection__container {
  z-index: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.heroSectionInfo__container {
  flex-basis: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.heroSectionInfo__container-child {
  flex-basis: 80%;
  margin: auto;
}

.heroSectionInfo__container-child p {
  letter-spacing: 1.1px;
  color: var(--text-color);
  text-align: justify;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  line-height: 1.4;
}

.heroSectionInfo__container-child * {
  margin: 1rem 0;
}

.heroSectionInfo__container-child h1 {
  color: var(--headline-color);
  font-size: 6rem;
}

.heroSectionInfo__container-child h1 span {
  color: var(--cta-button-color);
}

.heroSectionPic__container {
  flex-basis: 50%;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 90vh;
  display: flex;
  position: relative;
}

.wrapper {
  overflow: hidden;
}

.wrapper .text {
  color: var(--cta-button-color);
  font-size: 4rem;
  font-weight: 600;
  position: relative;
}

.wrapper .text.first-text {
  color: var(--text-color);
}

.text.sec-text:before {
  content: "";
  background-color: var(--background-color);
  border-left: 2px solid var(--cta-button-color);
  width: 100%;
  height: 100%;
  animation: 6s steps(20, end) infinite animate;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes animate {
  40%, 60% {
    left: calc(100% + 4px);
  }

  100% {
    left: 0%;
  }
}

#canvas1 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#image1 {
  width: 100%;
  height: 100%;
  display: none;
}

@media (width <= 1300px) {
  .heroSection__container {
    flex-direction: row;
  }

  .heroSectionPic__container {
    flex-basis: 40%;
    justify-content: center;
    align-items: center;
    min-height: 500px;
    display: flex;
  }

  .heroSectionInfo__container {
    flex-basis: 60%;
  }

  .heroSectionPic__container img {
    border-radius: 50%;
    height: 400px;
    display: flex;
  }
}

@media (width <= 800px) {
  .heroSection__container {
    z-index: 0;
    flex-direction: column;
    position: relative;
  }

  .heroSectionPic__container {
    z-index: 0;
    flex-basis: 100%;
    justify-content: center;
    align-items: center;
    min-height: 500px;
    display: flex;
    position: relative;
  }

  .heroSectionInfo__container {
    text-align: center;
    flex-basis: 100%;
    order: 2;
  }

  .heroSectionPic__container img {
    z-index: -10;
    border-radius: 50%;
    height: 400px;
    display: flex;
    position: relative;
  }
}

.projectSection__container-flex {
  z-index: 100;
  flex-direction: column;
  width: 100%;
  min-width: 290px;
  min-height: 80vh;
  display: flex;
  position: relative;
}

.ongoingLabel {
  text-align: center;
  margin-top: -30px;
  font-size: 2rem;
  font-weight: bolder;
  color: #adff2f !important;
}

.projectSection__container-flex .title {
  text-align: center;
  color: var(--headline-color);
  font-size: 4rem;
}

.projectSection__container-flex:before {
  content: "";
  background: var(--right-middleCircle-color);
  clip-path: circle(30% at 76% 60%);
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 133px;
  left: 125px;
}

.projectSection__container-flex:after {
  content: "";
  background: var(--left-upperCircle-color);
  clip-path: circle(18% at 15% 40%);
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: -195px;
  left: -79px;
}

.card__container-main_flex {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  gap: 4%;
  width: 100%;
  display: flex;
}

.card {
  background: var(--card-color);
  opacity: .7;
  box-shadow: var(--card-shadow);
  border-radius: 2rem;
  flex-direction: column;
  width: 35rem;
  min-width: 25rem;
  min-height: 350px;
  margin: 2rem;
  padding: 1rem;
  transition: all .2s ease-in-out;
  display: flex;
  position: relative;
  overflow: hidden;
}

.card:hover {
  transform: scale(1.1);
}

.card:hover h2 {
  color: var(--cta-button-color);
}

.card .content {
  text-align: center;
}

.card h2 {
  color: var(--card-number-color);
  font-size: 20rem;
  position: absolute;
  top: -227px;
  right: -15px;
}

.card .content {
  z-index: 100;
}

.card .content .cardInfo h3 {
  color: var(--headline-color);
  font-size: 4rem;
}

.card .content .cardInfo p {
  color: var(--text-color);
  font-size: 1.5rem;
}

.techStack__technologies img {
  background-position: center;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-right: 1rem;
}

.techStack__technologies {
  margin-bottom: 2rem;
}

.stick_position_ghost_btn {
  position: absolute;
  bottom: 1.2rem;
  left: 36%;
  transform: translateX(-50%);
}

.stick_position_cta_btn {
  position: absolute;
  bottom: 1.2rem;
  left: 69%;
  transform: translateX(-50%);
}

@media (width <= 1000px) {
  .card__container-main_flex {
    gap: 10px;
  }
}

@media (width <= 800px) {
  .projectSection__container-flex:before, .projectSection__container-flex:after {
    display: none;
  }

  .card {
    box-shadow: 1rem 1rem 3rem var(--cta-button-color);
    margin-bottom: 2rem;
  }
}

@media (width <= 500px) {
  .card {
    box-shadow: 1rem 1rem 3rem var(--cta-button-color);
    width: 25rem;
    margin-bottom: 2rem;
  }

  .techStack__technologies img {
    background-position: center;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    margin-top: 1rem;
    margin-right: 1rem;
  }

  .techStack__technologies {
    margin-bottom: 0;
  }

  .stick_position_ghost_btn {
    position: absolute;
    bottom: 4.2rem;
    left: 30%;
    transform: translateX(-50%);
  }

  .stick_position_cta_btn {
    position: absolute;
    bottom: 4.2rem;
    left: 78%;
    transform: translateX(-50%);
  }

  .card .content .cardInfo h3 {
    color: var(--headline-color);
    font-size: 3rem;
  }

  .card .content .cardInfo p {
    color: var(--text-color);
    font-size: 1.2rem;
  }
}

.resumeSection__main-container {
  width: 100%;
  position: relative;
}

.resumeSection__main-container:after {
  content: "";
  background: var(--left-lowerCircle-color);
  clip-path: circle(20% at 18% 45%);
  z-index: -1;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: -518px;
  left: -79px;
}

.resumeSection__grid-container {
  grid-template-columns: 15rem 1fr;
  grid-auto-flow: dense;
  gap: 5vw;
  display: grid;
}

.resumeSection__grid-container main {
  grid-column: 2;
  padding: 7rem 2rem;
  overflow: hidden;
}

.resumeSection__grid-container main .eyebrows {
  color: var(--headline-color);
  align-items: center;
  margin: 0;
  font-size: 4rem;
  display: flex;
}

.resumeSection__grid-container main .eyebrows span.resumeDownload img {
  vertical-align: baseline;
  cursor: pointer;
  width: 50px;
  height: 50px;
  margin-left: 3rem;
  display: inline-block;
}

.resumeSection__grid-container main .intro {
  color: var(--text-color);
  text-align: justify;
  max-width: 100ch;
  font-size: 1.5rem;
  line-height: 1.4;
}

.resumeSection__grid-container main .job-experience {
  scrollbar-color: var(--cta-button-color) var(--card-color);
  grid-auto-flow: column;
  justify-content: start;
  gap: 2rem;
  padding-block: 2rem;
  display: grid;
  position: relative;
  overflow-x: auto;
}

.resumeSection__grid-container main .job {
  background: var(--card-color);
  border-radius: 1rem;
  width: 28.5rem;
  margin-left: 2rem;
  padding: 2rem;
  transition: all .2s ease-in-out;
}

.resumeSection__grid-container main .job:hover {
  transform: scale(1.1);
}

.resumeSection__grid-container main .job-experience::-webkit-scrollbar {
  height: .75rem;
}

.resumeSection__grid-container main .job-experience::-webkit-scrollbar-track {
  background-color: var(--card-color);
  border-radius: 100vw;
}

.resumeSection__grid-container main .job-experience::-webkit-scrollbar-thumb {
  background-color: var(--cta-button-color);
  border-radius: 100vw;
}

.resumeSection__grid-container main .job-info {
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 1rem;
  display: flex;
}

.resumeSection__grid-container main .job-experience .job .role {
  color: var(--headline-color);
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 1.2;
}

.resumeSection__grid-container main .job-experience .job:hover .role {
  color: var(--cta-button-color);
}

.resumeSection__grid-container main .job-experience .job .company {
  color: var(--headline-color);
  text-transform: capitalize;
  font-size: 1.725rem;
}

.resumeSection__grid-container main .job-experience .job .company-info {
  color: var(--text-color);
  font-size: 1.2rem;
  line-height: 1.4;
}

.resumeSection__grid-container main .job-experience .job .year {
  color: var(--cta-button-color);
  font-size: 1.25rem;
}

.edu-title {
  color: var(--headline-color);
  margin: 5rem 0 0;
  font-size: 3rem;
}

.edu-title span {
  color: var(--cta-button-color);
}

.resumeSection__grid-container aside {
  grid-column: 1;
  align-self: stretch;
}

.resumeSection__grid-container aside .icon-list {
  border-left: 2px solid var(--cta-button-color);
  z-index: -1;
  align-content: center;
  gap: 3rem;
  height: 100%;
  margin: 0 0 0 max(5rem, min(0vw, 10rem));
  padding: 0;
  list-style: none;
  display: grid;
  position: relative;
}

.resumeSection__grid-container aside .icon-list > li {
  margin-top: 3rem;
  padding-left: 2rem;
  position: relative;
}

.resumeSection__grid-container aside .icon-list li:first-child {
  margin-top: 17rem;
}

.resumeSection__grid-container aside .icon-list > li img {
  width: 5rem;
  height: 5rem;
}

.resumeSection__grid-container aside .icon-list > li:after {
  --_size: 2rem;
  content: "";
  width: var(--_size);
  aspect-ratio: 1;
  background-color: var(--design-element-color);
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%);
}

@media (width <= 560px) {
  .resumeSection__main-container:after {
    display: none;
  }

  .resumeSection__grid-container {
    grid-template-columns: 4rem 1fr;
  }

  .resumeSection__grid-container aside .icon-list {
    z-index: 1;
    margin: 0;
  }

  .resumeSection__grid-container aside .icon-list > li img {
    width: 3rem;
    height: 3rem;
  }

  .resumeSection__grid-container aside .icon-list > li:after {
    --_size: 1rem;
  }

  .resumeSection__grid-container main .eyebrows {
    color: var(--headline-color);
    margin: 0;
    font-size: 3rem;
  }

  .resumeSection__grid-container main .intro {
    color: var(--text-color);
    text-align: justify;
    max-width: 100ch;
    font-size: 1.2rem;
    line-height: 1.3;
  }

  .edu-title {
    color: var(--headline-color);
    margin: 3rem 0 0;
    font-size: 2rem;
  }

  .resumeSection__grid-container main .job {
    background: var(--card-color);
    cursor: pointer;
    border-radius: .5rem;
    width: 28.5rem;
    margin-left: 2rem;
    padding: 1rem;
    transition: all .2s ease-in-out;
  }

  .resumeSection__grid-container main .job-experience .job .role {
    color: var(--headline-color);
    text-transform: uppercase;
    font-size: 1.5rem;
    line-height: 1.2;
  }

  .resumeSection__grid-container main .job-experience .job .year {
    color: var(--cta-button-color);
    font-size: 1.1rem;
  }

  .resumeSection__grid-container main .job-experience .job .company {
    color: var(--headline-color);
    text-transform: capitalize;
    font-size: 1.3rem;
  }

  .resumeSection__grid-container main {
    grid-column: 2;
    padding: 7rem 0 7rem 2rem;
    overflow: hidden;
  }

  .resumeSection__grid-container aside .icon-list li:first-child {
    margin-top: 0;
  }
}

.contactSection__main-container {
  width: 100%;
}

.contactSection__main-container .contact-title {
  color: var(--headline-color);
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
  margin-bottom: 2rem;
  font-size: 4rem;
  display: flex;
}

.contactSection__box {
  grid-template: "form info" 5fr
                 "form map" 4fr
                 / 2fr 1fr;
  gap: 20px;
  width: 80%;
  margin: 3rem auto;
  padding: 1rem;
  display: grid;
}

.form {
  grid-area: form;
}

.map {
  grid-area: map;
}

.info {
  grid-area: info;
}

.contact {
  background-color: var(--card-color);
  padding: 40px;
}

.contact h3 {
  color: var(--cta-button-color);
  margin-bottom: 1rem;
  font-size: 2.5rem;
}

.formBox {
  width: 100%;
  position: relative;
}

.formBox .row50 {
  gap: 20px;
  display: flex;
}

.inputBox {
  flex-direction: column;
  width: 50%;
  margin-bottom: 10px;
  display: flex;
}

.formBox .row100 .inputBox {
  width: 100%;
}

.inputBox label {
  color: var(--headline-color);
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-size: 2rem;
}

.inputBox input {
  background-color: var(--background-color);
  color: var(--headline-color);
  letter-spacing: 2px;
  border: none;
  outline: none;
  margin-bottom: 1rem;
  padding: 2rem;
  font-size: 1.5rem;
}

.inputBox input:disabled {
  opacity: .5;
  background-color: #ff4500;
}

.error-message {
  color: red;
  font-size: 1.3rem;
}

.inputBox textarea {
  background-color: var(--background-color);
  color: var(--headline-color);
  letter-spacing: 2px;
  resize: none;
  border: none;
  outline: none;
  min-height: 10rem;
  margin-bottom: 2rem;
  padding: 2rem;
  font-size: 1.5rem;
}

.inputBox input[type="submit"] {
  background-color: var(--cta-button-color);
  color: var(--headline-color);
  cursor: pointer;
  border: none;
  max-width: 12rem;
  padding: 1.5rem 1.4rem;
  font-size: 2rem;
}

.info h3 {
  color: var(--background-color);
  margin-bottom: 2rem;
}

.info {
  background: var(--cta-button-color);
}

.info .infoBox div {
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
}

.info .infoBox img {
  width: 3rem;
  height: 3rem;
  margin-right: 2rem;
}

.info .infoBox div p {
  letter-spacing: 2px;
  font-size: 1.5rem;
}

.socialMedia {
  flex-gap: 4px;
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;
  margin: 40px 0 0;
  padding: 0;
  display: flex;
}

.socialMedia li {
  transition: all .2s ease-in-out;
}

.socialMedia li:hover {
  transform: scale(1.3);
}

.map {
  padding: 0;
}

.map iframe {
  width: 100%;
  height: 100%;
}

@media (width <= 1220px) {
  .contactSection__box {
    grid-template: "form form" 5fr
                   "info map" 2fr
                   / 2fr 2fr;
    gap: 20px;
    padding: 1rem;
    display: grid;
  }

  .info, .map {
    max-height: 350px;
  }
}

@media (width <= 800px) {
  .contactSection__box {
    grid-template: "form form" 2fr
                   "info info" .5fr
                   "map map" .5fr
                   / 1fr 1fr;
    gap: 20px;
    padding: 1rem;
    display: grid;
  }

  .info {
    max-height: 300px;
  }

  .map {
    max-height: 250px;
  }

  .formBox .row50 {
    flex-direction: column;
    gap: 0;
    display: flex;
  }

  .inputBox {
    width: 100%;
  }
}

@media (width <= 450px) {
  .info .infoBox div {
    flex-direction: column;
    display: flex;
  }

  .info .infoBox div p {
    word-wrap: break-word;
    font-size: 1.2rem;
  }

  .info h3 {
    color: var(--background-color);
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2rem;
  }

  .socialMedia {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1rem;
  }

  .info .infoBox img {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }

  .inputBox input[type="submit"] {
    max-width: 100%;
  }

  .contact {
    padding: 8px;
  }
}

.footerSection-container {
  background-color: var(--card-color);
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 1rem;
  display: flex;
}

.footerSection-container h3 {
  letter-spacing: 2px;
  color: var(--cta-button-color);
  text-align: center;
  font-size: 1.5rem;
}
/*# sourceMappingURL=index.072c896e.css.map */
