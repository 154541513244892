.navbar__container{
    border: 2px solid var(--navbar-border-color);
    background-color: var(--background-color);
    opacity: 0.8;
    width: 100%;
    max-height: 200px;
    margin: 0 auto;
    /* box-shadow: 5px 0px 9px 0px #FF0000 ; */
    border-radius: 2rem;
    position: relative;
    z-index: 10000;
    position: -webkit-sticky;
    position: sticky;
    top: 0;


}

.navbar__flex-container img{
    width: 100%;
    margin-left: 2rem;
    max-width: 100px;
    max-height: 100px;
    cursor: pointer;
}

.navbar__flex-container{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
}

.navbar__links-menu{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
}


.navbar__flex-container :first-child{
    margin-right: auto;
}

.navbar__links-menu p{
    padding: 1rem 3rem ;
    font-size: 2rem;
    display: block;
    cursor: pointer;
} 

.navbarToggleMenu{
    display: none;
    position: relative;
    margin-right: 2rem;
    z-index: 1000;
}


.navbarToggleMenu svg{
    cursor: pointer;
}


.navbarToggleMenu__container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background:var(--design-element-color);
    padding: 2rem;
    position:absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 21rem;
    border-radius: 3rem;
    box-shadow: 0px 4px 8px var(--cta-button-color) ;



}
.navbarToggleMenu__container-links{
    margin: auto;
}

.navbarToggleMenu__container-links p a{
    color: var(--toggleMenuLinks-color);
    font-size: 2.5rem;
}

.navbarToggleMenu__container-links p{
    padding: 1rem;
    cursor: pointer;
}
.navbarToggleMenu__container-links p button.call_to_action_btn{
    background-color: var(--background-color);
    color: var(--design-element-color);
}




/* this is more dark and lightmode */

.sunLightMode img{
    height: 4rem;
    width: 4rem;
    padding: 0;
    margin: 0;
}

.sunLightMode{
    background:transparent;
    outline: none;
    border: none;
    margin-right: 4rem;
}

@media screen and (max-width:800px){
    .navbar__links-menu{
        display: none;
    }

    .navbarToggleMenu{
        display: flex;
        margin-right: 2rem;
        
    }
}

