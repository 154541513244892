@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&family=Roboto:wght@300;400;500;700&display=swap');



html {
    font-family: 'Roboto', 'Montserrat', sans-serif;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: 62.5%;
    /* color-scheme: dark; */

    
    background-color: var(--background-color);
    color: var(--text-color); 

}

/* *the following properties are going to change in dark to light mode --> bgcolr color and ctabtn*/

.call_to_action_btn{
    outline: none;
    background-color: var(--cta-button-color);
    border-radius: 2rem;
    box-sizing: content-box;
    padding: 1rem 2rem;
    color: var(--headline-color);
    border: none;
    font-size: 2rem;
    cursor: pointer;
}

.call_to_action_btn:hover{
    color: var(--cta-button-color);
    background-color: var(--headline-color);
}


.ghost_button{
    outline: none;
    border: 1px solid var(--cta-button-color);
    background-color: var(--background-color);
    border-radius: 2rem;
    box-sizing: content-box;
    padding: 1rem 2rem;
    color: var(--headline-color);
    font-size: 2rem;
    margin-right: 1rem;
    cursor: pointer;
    
}

.ghost_button:hover{
    color:var(--cta-button-color) ;
    border: 1px solid var(--headline-color);
}

.container {
    width: 100%;
    max-width: 85vw;
    min-height: 100vh;
    margin: 0 auto;
    padding: 1rem;
    /* border: 2px solid red; */
}

a {
    text-decoration: none;
    text-transform: capitalize;
    color: var(--headline-color);
    font-weight: bolder;
}

a:hover{
    color:var(--cta-button-color)
}
a:focus{
    color:var(--cta-button-color)
}

li {
    list-style: none;
}

:root{
    --cta-button-color: #FF0000;
    --headline-color: #FFFFFF;
    --text-color: #E5E5E5;
    --background-color: #000000;
    --design-element-color: #950101;
    --card-color:#17141d;
    --navbar-border-color:#555551;
    --card-number-color:#858282;
    --card-shadow :1rem 0 7rem var(--background-color);
    --toggleMenuLinks-color:var(--background-color);
    --right-middleCircle-color:linear-gradient(var(--cta-button-color), var(--background-color));
    --left-upperCircle-color:linear-gradient(to left top, var(--background-color) 60%, var(--cta-button-color));
    --left-lowerCircle-color:linear-gradient(to left ,var(--cta-button-color) 60%,var(--background-color));

}

.lightMode{
    --cta-button-color: #c97712;
    --headline-color: #000000;
    --text-color: #000000;
    --background-color: #FFF8EA  ;
    --design-element-color: #E5BA73;
    --card-color: #FAEAB1;
    --navbar-border-color:#555551;
    --card-number-color:#6d6666d4;
    --card-shadow:none;
    --toggleMenuLinks-color:#000000;
    --right-middleCircle-color: linear-gradient(var(--cta-button-color) 40%, var(--background-color));
    --left-upperCircle-color : linear-gradient(to left top, var(--background-color) 45%, var(--cta-button-color));
    --left-lowerCircle-color:linear-gradient(to left ,var(--cta-button-color) 60%,var(--background-color));
}

/* 

llight mode final option :
   --cta-button-color: #c97712;
    --headline-color: #000000;
    --text-color: #000000;
    --background-color: #FFF8EA  ;
    --design-element-color: #E5BA73;
    --card-color: #FAEAB1;
    --navbar-border-color:#555551;
    --card-number-color:#6d6666d4;
    
    ______________________________________


light mode option one :
   --cta-button-color: #C58940;
    --headline-color: #000000;
    --text-color: #000000;
    --background-color: #FFF8EA  ;
    --footer-color: #3D0000;
    --design-element-color: #E5BA73;
    --card-color: #FAEAB1;
    --navbar-border-color:#555551;
    --card-number-color:#6d6666d4;
 */
/* 
 --footer-color: #3D0000;
dark mode :
     --cta-button-color: #FF0000;
    --headline-color: #FFFFFF;
    --text-color: #E5E5E5;
    --background-color: #000000;
    --design-element-color: #950101;
    --card-color:#17141d;
    --navbar-border-color:#555551;
    --card-number-color:#858282;
 */




/* * some animation classes  */

.vibrate-1 {
    -webkit-animation: vibrate-1 2s ease-in-out infinite both;
    animation: vibrate-1 1s ease-in-out infinite both;
}


@-webkit-keyframes vibrate-1 {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }

    20% {
        -webkit-transform: translate(-2px, 2px);
        transform: translate(-2px, 2px);
    }

    40% {
        -webkit-transform: translate(-2px, -2px);
        transform: translate(-2px, -2px);
    }

    60% {
        -webkit-transform: translate(2px, 2px);
        transform: translate(2px, 2px);
    }

    80% {
        -webkit-transform: translate(2px, -2px);
        transform: translate(2px, -2px);
    }

    100% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}

@keyframes vibrate-1 {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }

    20% {
        -webkit-transform: translate(-2px, 2px);
        transform: translate(-2px, 2px);
    }

    40% {
        -webkit-transform: translate(-2px, -2px);
        transform: translate(-2px, -2px);
    }

    60% {
        -webkit-transform: translate(2px, 2px);
        transform: translate(2px, 2px);
    }

    80% {
        -webkit-transform: translate(2px, -2px);
        transform: translate(2px, -2px);
    }

    100% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}



.scale-up-center {
    -webkit-animation: scale-up-center 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-center 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


@-webkit-keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

/* text popup class */

.text-pop-up-top {
    -webkit-animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes text-pop-up-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        text-shadow: none;
    }

    100% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
    }
}

@keyframes text-pop-up-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        text-shadow: none;
    }

    100% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
    }
}