/* .heroSection__container{
 
 display: flex;
 flex-flow: row wrap;
 width: 100%;


 height: 100vh;
 justify-content: center;
 align-items: center;
 position: relative;


} */
.heroSection__container {
    display: flex;
    /* flex-flow: row wrap; */ /* Remove this line */
    width: 100%;
    /* height: 100vh; */
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
  }

.heroSectionInfo__container{
    flex-basis: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.heroSectionInfo__container-child{
    flex-basis: 80%;
    margin: auto;
    /* border: 2px solid yellow; */
}
.heroSectionInfo__container-child p{
    font-size:1.5rem;
    line-height: 1.4;
    letter-spacing: 1.1;
    color: var(--text-color);
    text-align: justify;
    margin-bottom: 2rem;
}
.heroSectionInfo__container-child *{
    margin: 1rem 0;
}
.heroSectionInfo__container-child h1 {
    font-size: 6rem;
    color: var(--headline-color);
}
.heroSectionInfo__container-child h1 span{
    color: var(--cta-button-color)
}

.heroSectionPic__container{
    position: relative;
    flex-basis: 50%;
    width: 100%;
    min-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}



/* animation type writinge effect */
.wrapper{
    overflow: hidden;
}

.wrapper .text{
    position: relative;
    color:var(--cta-button-color);
    font-size: 4rem;
    font-weight: 600;
}

.wrapper .text.first-text{
    color: var(--text-color);
}

.text.sec-text:before{
    content: "";
    position:absolute;
    top: 0;
    left:0;
    height: 100%;
    width: 100%;
    background-color: var(--background-color);
    border-left: 2px solid var(--cta-button-color);
    animation: animate 6s steps(20) infinite;

}

@keyframes animate{
    40%,60%{
        left: calc(100% + 4px);
    }
    100%{
        left: 0%;
    }
}

/* typing animation ends here */



#canvas1 {
    /* height: 100%;
    width: 100%;
    position: relative;
    z-index:-2; */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* object-fit:   cover; */
    /* border: 2px solid green; */
    /* display: none;  */
    /* border: 2px solid red; */
}

#image1 {
    /* position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-position: center;
    background-size: cover; */
    /* object-fit: cover;
    object-position: center; */
    width: 100%;
    height: 100%;
    display: none; 
   
}
@media (max-width:1300) {
    .heroSection__container{
        flex-direction: row;

    }
    .heroSectionPic__container{
        display: flex;
        flex-basis: 40%;
        justify-content: center;
        align-items: center; 
        min-height: 500px;
 
     }
     .heroSectionInfo__container{
        /* order: 2; */
        flex-basis: 60%;
        /* text-align: center; */
    }
    .heroSectionPic__container img {
        display: flex;
        height: 400px;
        
        border-radius: 50%;
    }

}

@media (max-width: 800px) {
    .heroSection__container{
        flex-direction: column;
        position: relative;
        z-index: 0;
       

    }
    .heroSectionPic__container{
       display: flex;
       flex-basis: 100%;
       justify-content: center;
       align-items: center; 
       min-height: 500px;
       position: relative;
       z-index: 0;
       

    }
    .heroSectionInfo__container{
        order: 2;
        flex-basis: 100%;
        text-align: center;
    }
    .heroSectionPic__container img {
        display: flex;
        height: 400px;
        
        border-radius: 50%;
        position: relative;
        z-index: -10;
    }

  }