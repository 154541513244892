.footerSection-container{
    /* border: 2px solid red; */
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--card-color);
    border-top-left-radius: 3rem;
    border-top-right-radius: 3rem;
    padding: 1rem;
}

.footerSection-container h3{
    font-size: 1.5rem;
    letter-spacing: 2;
    color: var(--cta-button-color);
    text-align: center;
}